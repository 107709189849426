import axios from "axios";


const baseURL= process.env.APP_TERMINAL_API_URL+ "/api/v1";

class UserDataService {
  getAll(type) {

    let url = baseURL+'/users'+ ( (type && type != "") ? ("?type="+type) : "" );

    return axios.get(url, {
     headers: {
      "Content-type": "application/json",
       'Authorization': localStorage.getItem('id_token')
     }
    });
  }

  get(id) {
    return axios.get(baseURL+`/users/${id}`, {
     headers: {
      "Content-type": "application/json",
       'Authorization': localStorage.getItem('id_token')
     }
    });
  }


  getAvatar(id) {
    return axios.get(baseURL+`/users/${id}/avatars`, {
     headers: {
      "Content-type": "application/json",
       'Authorization': localStorage.getItem('id_token')
     }
    });
  }

  postByUrl(url, countryCode) {

    return axios.post(baseURL+"/users/"+url+ ( (countryCode && countryCode != "") ? ("&countryCode="+countryCode) : "" ), {}, {
     headers: {
      "Content-type": "application/json",
       'Authorization': localStorage.getItem('id_token')
     }
    });
  }

  createExternalUser(data, countryCode) {
    return axios.post(baseURL+'/users/external'+ ( (countryCode && countryCode != "") ? ("?countryCode="+countryCode) : "" ), data, {
     headers: {
       "Content-type": "application/json"
     }
    });
  }

  create(data) {
    return axios.post(baseURL+'/users', data, {
     headers: {
       "Content-type": "application/json",
       'Authorization': localStorage.getItem('id_token')
     }
    });
  }


  update(id, data) {
    return axios.put(baseURL+'/users/'+id, data, {
     headers: {
       "Content-type": "application/json",
       'Authorization': localStorage.getItem('id_token')
     }
    });
  }

  sendMail(username, countryCode) {

    let url = baseURL+'/users/recoveryPassword'+ ( (username && username != "") ? ("?username="+username) : "" )+ ( (countryCode && countryCode != "") ? ("&countryCode="+countryCode) : "" );

    return axios.post(url, {}, {
     headers: {
       "Content-type": "application/json",
       'Authorization': localStorage.getItem('id_token')
     }
    });
  }

  sendContactMail(body) {

    let url = baseURL+'/users/contactUs';

    return axios.post(url, body, {
     headers: {
       "Content-type": "application/json",
       'Authorization': localStorage.getItem('id_token')
     }
    });
  }

  activeUser(token, countryCode) {

    let url = baseURL+'/users/active'+ ( (countryCode && countryCode != "") ? ("?countryCode="+countryCode) : "" );

    return axios.post(url, {}, {
     headers: {
       "Content-type": "application/json",
       'Authorization': "Bearer "+token
     }
    });
  }

  getCurrentUser() {

    return axios.get(baseURL+'/users/current', {
     headers: {
      "Content-type": "application/json",
       'Authorization': localStorage.getItem('id_token')
     }
    });
  }

  refreshToken() {

    return axios.post(baseURL+'/users/refreshToken', {}, {
     headers: {
       "Content-type": "application/json",
       'Authorization': localStorage.getItem('id_token')
     }
    });
  }

  login(username, pwd) {

    var token = 'Basic ' + Buffer.from(username+':'+pwd+":induction").toString('base64');

    return axios.post(baseURL+'/users/login', {}, {
     headers: {
       'Authorization': token
     }
    });
  }

}

export default new UserDataService();