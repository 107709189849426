import React from "react";
import PropTypes from "prop-types";
import { withRouter, Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Alert, Button, Label, Input, FormGroup } from 'react-bootstrap';
import Widget from "../../components/Widget";
import { loginUser } from "../../actions/user";
import s from './Login.module.scss';
import signinImg from "../../images/signinImg.svg";
import logo from "../../images/login_image.jpeg";
import img1 from "../../images/Vector-1.svg";
import img2 from "../../images/Vector-2.svg";
import img3 from "../../images/Vector-3.svg";
import img4 from "../../images/Vector-4.svg";
import UserService from "../../services/UserService";
import auth from '../../Auth';
import { FaTimesCircle } from "react-icons/fa";

import { getAnalytics, logEvent } from "firebase/analytics";

class ResetPassword extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  static isAuthenticated(token) {
    if (token) return true;
  }

  constructor(props) {
    super(props);

    this.state = {
      email: ""
    };

    this.doResetPassword = this.doResetPassword.bind(this);
    this.resendPassword = this.resendPassword.bind(this);
    this.changeEmail = this.changeEmail.bind(this);
  }

  changeEmail(event) {
    event.target.setCustomValidity('');

    this.setState({ email: event.target.value });
  }

  resendPassword(event, link) {
    event.preventDefault();

    this.setState({
      loading: true,
      errorLogin: null,
      errorNetLogin: null,
      success: false
    })

    UserService.postByUrl(link, this.props.lang).then(response => {
      this.setState({
        loading: false,
        success: true
      })
    })
      .catch(e => {
        this.setState({
          loading: false,
          errorLogin: e.response,
          errorNetLogin: e.message
        })
      });
  }

  doResetPassword(e) {
    e.preventDefault();

    this.setState({
      loading: true
    });

    UserService.sendMail(this.state.email, this.props.lang).then(response => {
      this.setState({
        loading: false,
        errorLogin: null,
        errorKey: null,
        errorLink: null,
        success: true
      });
      const analytics = getAnalytics();
      logEvent(analytics, 'reset_password', {
        user: this.state.email
      });
    })
      .catch(e => {
        this.setState({
          loading: false,
          errorLogin: e.response,
          errorKey: (e.response != null && e.response.data != null && e.response.data.error_key != null) ? e.response.data.error_key : null,
          errorLink: (e.response != null && e.response.data != null && e.response.data.link != null) ? e.response.data.link : null,
          errorNetLogin: e.message
        })
      });
  }

  render() {
    const { from } = this.props.location.state || { from: { pathname: '/app' } }; // eslint-disable-line

    var isAuthenticated = auth.isAuthenticated();

    // cant access login page while logged in
    if (isAuthenticated) {
      return (
        <Redirect to={from} />
      );
    }

    let loading;

    if (this.state.loading) {
      loading = (
        <div>
          {this.context.t('loading')}
        </div>
      )
    }

    let errorLogin



    if (this.state.errorNetLogin) {
      errorLogin = <Alert variant="danger" style={{ margin: "20px" }}>
        {this.context.t('reset_password.error')}
      </Alert>;
    }

    if (this.state.errorLogin) {
      var errorKey = this.state.errorKey;
      var errorLink = this.state.errorLink;

      errorLogin = <Alert variant="danger" style={{ margin: "20px" }}>
        <strong><FaTimesCircle /></strong> {this.context.t(errorKey)}
        {errorKey == 'user_inactive' ? (
          <a onClick={event => this.resendPassword(event, errorLink)}> {this.context.t('click.here')} </a>
        ) : (
          ""
        )}
      </Alert>;
    }

    let sent = "";

    if (this.state.success) {
      sent = <Alert variant="success" style={{ margin: "20px" }}>
        {this.context.t('mail.success')}
      </Alert>;
    }

    return (

      <section>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              {errorLogin}
              {sent}
              {loading}
              <div className="wrapper-page">

                <div className="m-t-40 card-box">
                  <div className="text-center">
                    <h2 className="text-uppercase m-t-0 m-b-30">
                      <a className="text-success"> <span><img src={logo} alt="" height="70&quot;" /></span>
                      </a>
                    </h2>
                  </div>
                  <div className="account-content">
                    <div className="text-center m-b-20">
                      <p className="text-muted m-b-0 line-h-24" style={{ fontSize: "14px", marginBottom: "20px" }}>
                        {this.context.t('reset_password.text')}
                      </p>
                    </div>
                    <form method="POST" className="form-horizontal" onSubmit={this.doResetPassword}>

                      <div className="form-group m-b-20">
                        <div className="col-12">
                          <label htmlFor="emailaddress">{this.context.t('reset_password.user')}</label>
                          <input className="form-control" tabIndex="1" placeholder={this.context.t('reset_password.user')} value={this.state.email} onChange={this.changeEmail} type="text" required disabled={this.state.loading} onInvalid={e => e.target.setCustomValidity(this.context.t('field.required'))} onValid={e => e.target.setCustomValidity('')} />
                        </div>
                      </div>

                      <div className="form-group account-btn text-center m-t-10">
                        <div className="col-12">
                          <button id="btnResetPassword" className="btn btn-lg btn-block button button--primary" tabIndex="2" type="submit" disabled={this.state.loading}>{this.context.t('reset_password.send')}</button>
                          <br /><br />
                        </div>
                      </div>
                    </form>

                    <div className="clearfix"></div>

                  </div>
                </div>
                <div className="row m-t-50">
                  <div className="col-sm-12 text-center">
                    <p className="text-muted">{this.context.t('reset_password.back')}<a href="/induccion/login" className="text-dark m-l-5">Login</a></p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    );
  }
}

ResetPassword.contextTypes = {
  t: PropTypes.func.isRequired
}

function mapStateToProps(state) {
  return {
    isFetching: state.auth.isFetching,
    isAuthenticated: state.auth.isAuthenticated,
    errorMessage: state.auth.errorMessage,
    lang: state.i18nState.lang
  };
}

export default withRouter(connect(mapStateToProps)(ResetPassword));