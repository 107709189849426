import axios from "axios";

const baseURL= process.env.APP_TERMINAL_API_URL+ "/api/v1";

class ModuleDataService {

  getAll(countryCode) {
    return axios.get(baseURL+'/madeModules?countryCode='+countryCode, {
     headers: {
      "Content-type": "application/json",
       'Authorization': localStorage.getItem('id_token')
     }
    });
  }

  get(id) {
    return axios.get(baseURL+`/madeModules/${id}`, {
     headers: {
      "Content-type": "application/json",
       'Authorization': localStorage.getItem('id_token')
     }
    });
  }

  getModule(id) {
    return axios.get(baseURL+`/modules/${id}`, {
     headers: {
      "Content-type": "application/json",
       'Authorization': localStorage.getItem('id_token')
     }
    });
  }

  create(data) {
    return axios.post(baseURL+'/madeModules', data, {
     headers: {
       "Content-type": "application/json",
       'Authorization': localStorage.getItem('id_token')
     }
    });
  }

  sign(id, data) {
    return axios.put(baseURL+`/madeModules/${id}/sign`, data, {
     headers: {
       "Content-type": "application/json",
       'Authorization': localStorage.getItem('id_token')
     }
    });
  }

  sendProof(countryCode, data) {
    return axios.post(baseURL+`/madeModules/sendProof`+ ( (countryCode && countryCode != "") ? ("?countryCode="+countryCode) : "" ), data, {
     headers: {
       "Content-type": "application/json",
       'Authorization': localStorage.getItem('id_token')
     }
    });
  }

  update(id, data) {

    return axios.put(baseURL+`/madeModules/${id}`, data, {
     headers: {
       "Content-type": "application/json",
       'Authorization': localStorage.getItem('id_token')
     }
    });
  }

  delete(id) {
    return axios.delete(baseURL+`/madeModules/${id}`, {
     headers: {
      "Content-type": "application/json",
       'Authorization': localStorage.getItem('id_token')
     }
    });
  }
}

export default new ModuleDataService();