import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Table,
  Card,
  Alert,
  Progress,
  Button,
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Input,
  Label,
  Badge,
  Modal
} from 'react-bootstrap';

import { Sparklines, SparklinesBars } from "react-sparklines";
import ModuleDataService from "../../services/ModuleService";
import CategoriesDataService from "../../services/CategoriesService";
import UserDataService from "../../services/UserService";
import jsPDF from "jspdf";
import { Redirect } from 'react-router-dom'
import water from "../../images/mark_water.jpeg";
import signed1 from "../../images/signed-1.png";
import signed2 from "../../images/signed-2.png";
import certificate from "../../images/certificate.jpeg";
import Widget from "../../components/Widget";
import s from "./Tables.modules.scss";
import Moment from 'moment';
import DataTable from 'datatables.net';
import 'datatables.net-responsive';
import $ from 'jquery'
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import RangeSlider from 'react-bootstrap-range-slider';


class Result extends Component {

  constructor(props) {
    super(props);
    this.retrieveModule = this.retrieveModule.bind(this);
    this.retrieveUser = this.retrieveUser.bind(this);
    this.doSign = this.doSign.bind(this);

    this.state = {
      authenticated: true,
      nationalId: null,
      password: null,
      madeModule: null,
      goToModules: false,
      error: null,
      loading: true
    };
  }

  componentDidMount() {
    this.retrieveModule();
    this.retrieveUser();
  }

  retrieveUser() {

    var self = this;
    var id = this.props.id;

    UserDataService.getCurrentUser()
      .then(response => {

        var user = response.data;

        self.setState({
          user: user
        });

      })
      .catch(e => {
        if (e.response != null && e.response.status != null && e.response.status === 403) {
          console.log('unauthorized, logging out ...');
          self.setState({
            open: true,
            authenticated: false,
            loading: false
          })
        } else {
          this.setState({
            loading: false,
            error: e.response,
            errorNet: e.message
          })
        }
      });
  }


  retrieveModule() {
    var id = this.props.id;
    var self = this;
    ModuleDataService.get(id)
      .then(response => {

        this.setState({
          madeModule: response.data,
          loading: false
        });

        console.log(response.data);
      })
      .catch(e => {


        if (e.response != null && e.response.status != null && e.response.status === 403) {
          console.log('unauthorized, logging out ...');
          this.setState({
            open: true,
            authenticated: false,
            loading: false
          })
        } else {
          this.setState({
            loading: false,
            error: e.response,
            errorNet: e.message
          })
        }

      });
  }

  generatePdf(id) {
    var user = this.state.user;
    var module = this.state.madeModule;

    var lMargin = 35; //left margin in mm
    var rMargin = 5; //right margin in mm
    var pdfInMM = 280;  // width of A4 in mm

    var doc = new jsPDF('landscape');

    doc.setFont("times", "italic");

    doc.addImage(certificate, "JPEG", 0, 0, 300, 190);
    doc.addImage(water, "JPEG", 100, 55, 100, 100);


    doc.addImage(signed1, "JPEG", 30, 150, 70, 15);
    doc.addImage(signed2, "JPEG", 195, 150, 70, 15);

    var lang = this.props.lang;

    var categoryName = "";
    var questionnaire = null;

    if (lang == "GB") {
      var categories = module.module.categories;
      questionnaire = module.questionnaireGB;

      for (var i = 0; i < categories.length; ++i) {
        var category = categories[i];
        if (category.name == module.category) {
          categoryName = category.nameInEnglish;
        }
      }
    } else {
      var categories = module.module.categories;
      questionnaire = module.questionnaireES;

      for (var i = 0; i < categories.length; ++i) {
        var category = categories[i];
        if (category.name == user.category) {
          categoryName = category.name;
        }
      }
    }

    var title = this.context.t('certificate_of') + " " + (questionnaire != null ? questionnaire.title : module.module.name) + " (ID: " + module.id + ")";

    doc.setFontSize(22);
    doc.text(title, 83, 43);

    doc.setFontSize(10);

    var fecha = this.context.t('modules.finish_date') + ": " + Moment(module.createDate).format('DD/MM/YYYY');
    doc.text(fecha, 210, 23);

    var fechaExpiracion = this.context.t('modules.expiration_date') + ": " + Moment(module.expirationDate).format('DD/MM/YYYY');
    doc.text(fechaExpiracion, 40, 23);

    doc.setFontSize(12);

    var description = questionnaire != null ? questionnaire.description : module.module.description;

    var lines = doc.splitTextToSize(description, (pdfInMM - lMargin - rMargin));
    doc.text(lMargin, 70, lines);

    doc.setFontSize(18);

    doc.setFontSize(24);
    var status = this.context.t('modules.approved') + " - " + module.percentDone + "% - " + module.countAnswersCorrect + " " + this.context.t('modules.of') + " " + module.countQuestions;
    if (module.status == "APPROVED") {
      doc.setTextColor("green");
      doc.text(status, 35, 90);
    } else {
      doc.setTextColor("red");
      doc.text(status, 35, 90);
    }

    doc.setTextColor("black");

    doc.text(this.context.t('user.name') + ": " + user.firstName + " " + user.lastName, 35, 100);

    doc.text(this.context.t('user.national_id') + ": " + user.nationalId, 35, 110);

    doc.text(this.context.t('user.category') + ": " + categoryName, 35, 120);

    doc.text(this.context.t('user.company') + ": " + user.company, 35, 130);

    return doc;
  }

  print(event, id) {
    var user = this.state.user;
    var module = this.state.madeModule;
    var doc = this.generatePdf(id);

    doc.save(module.module.name + "_" + user.nationalId + "_" + module.id + "_" + new Date().getTime() + ".pdf");
  }

  send(event, id) {

    var doc = this.generatePdf(id);
    var out = doc.output();

    var body = {
      "certificate.pdf": btoa(out)
    };

    var id = this.props.id;

    this.setState({
      loading: true
    });

    ModuleDataService.sendProof(id, this.props.lang, body).then(response => {
      this.setState({
        loading: false
      });
    })
      .catch(e => {
        this.setState({
          loading: false
        })
      });

  }

  handleClose(reason) {
    if (reason && reason == "backdropClick") return;
    this.setState({
      open: false
    })
    localStorage.removeItem("id_token");
    window.location.reload();
  }

  switchStatus(module) {
    if (module.expired) {
      return (
        <tr>
          <td className={"pl-0 text-danger fw-normal h1"}>
            {this.context.t('modules.expired')}
          </td>
        </tr>
      );
    } else {
      switch (module.status) {
        case 'APPROVED':
          return (
            <Table>
              <tbody>
                <tr>
                  <td className={"pl-0 text-success fw-normal h1"}>
                    {this.context.t('modules.approved')} - {module.percentDone}% - <br />{' '}
                    {this.context.t('modules.correct_answers')} {module.countAnswersCorrect}{' '}
                    {this.context.t('modules.of')} {module.countQuestions}
                  </td>
                </tr></tbody>
            </Table>
          );
        case 'DISAPPROVED':
          return (
            <Table>
              <tbody>
                <tr>
                  <td className={"pl-0 text-danger fw-normal h1"}>
                    {this.context.t('modules.disapproved')} - {module.percentDone}% - <br />{' '}
                    {this.context.t('modules.correct_answers')} {module.countAnswersCorrect}{' '}
                    {this.context.t('modules.of')} {module.countQuestions}
                  </td>
                </tr></tbody>
            </Table>
          );
        default:
          return (
            <Table>
              <tbody>
                <tr>
                  <td className={"pl-0 text-warning fw-normal h1"}>
                    {this.context.t('modules.pending')}
                  </td>
                </tr></tbody>
            </Table>
          );
      }
    }
  }

  signClose(event) {
    return false;
  }

  doSign(e) {
    e.preventDefault();
    var self = this;
    var password = $("#password").val();
    var id = this.props.id;

    this.setState({
      loading: true
    });

    var body = {
      "password": password
    }

    ModuleDataService.sign(id, body).then(response => {
      self.setState({
        madeModule: response.data,
        loading: false
      });
    })
      .catch(e => {
        this.setState({
          loading: false,
          errorSign: e.response,
          errorSignSave: e.message
        })
      });
  }

  render() {
    const { madeModule } = this.state;

    var redirect = '/app/modules';

    if (this.state.goToModules) {
      return <Redirect to={redirect} />
    }

    if (!this.state.authenticated) {
      return (
        <Modal
          show={this.state.open}
          onHide={(_, reason) => { this.handleClose(reason) }}>
          <Modal.Header closeButton>
            <Modal.Title>{this.context.t('session.expired')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.context.t('session.msg')} <br />
            {this.context.t('session.redirect')}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={e => { this.handleClose() }} autoFocus>
              {this.context.t('accept')}
            </Button>
          </Modal.Footer>
        </Modal>
      )
    }

    if (this.state.loading) {
      return (
        <div>
          {this.context.t('loading')}
        </div>
      )
    }




    if (this.state.error) {
      return (
        <Alert variant="danger" style={{ marginBottom: "20px" }}>
          <strong>{this.state.error.status}</strong>{this.context.t('error.unexpected')}
        </Alert>
      )
    }


    if (this.state.errorNet) {
      return (
        <Alert variant="danger" style={{ marginBottom: "20px" }}>
          {this.context.t('error.unexpected')}
        </Alert>
      )
    }

    var modal = "";

    if (this.state.madeModule.status == "APPROVED" && !this.state.madeModule.signed) {

      modal =
        (<Modal
          backdrop="static"
          keyboard={false}
          show={true}
          onHide={(_, reason) => { this.signClose(reason) }}>
          <Modal.Header closeButton>
            <Modal.Title>{this.context.t('sign.title')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form method="POST" className="form-horizontal" onSubmit={this.doSign}>

              <div className="form-group m-b-20">
                <div className="col-12">
                  <label htmlFor="password">{this.context.t('login.password')}</label>
                  <input id="password" className="form-control" tabIndex="2" placeholder={this.context.t('login.password')} type="password" required />
                </div>
              </div>

              <div className="form-group account-btn text-center m-t-10">
                <div className="col-12">
                  <button id="btnLogin" className="btn btn-lg btn-block button button--primary" tabIndex="2" type="submit">{this.context.t('sign.button')}</button>
                  <br /><br />
                </div>
              </div>

            </form>
          </Modal.Body>
        </Modal>
        );
    }

    return (
      <div>
        {modal}
        <Widget style={{ paddingTop: "20px" }}>
          <h3>
            {madeModule.module.name}
          </h3>

          <blockquote>
            {madeModule.module.description}
          </blockquote>

          <div style={{ marginBottom: "20px" }} />

          {this.switchStatus(madeModule)}

          <Row>
            <Col xs={12} lg={12}>
              <a className="btn btn-lg btn-block button button--primary" href="/induccion/app/modules" tabIndex="2" role="button">
                {this.context.t('modules.continue')}
              </a>
              <br /><br />
            </Col>
         </Row>

        </Widget>
      </div>

    );
  }
}

Result.contextTypes = {
  t: PropTypes.func.isRequired
}

export default connect(state => ({
  lang: state.i18nState.lang
}))(Result)