
class Auth {

  isAuthenticated = () => {
    return !!localStorage.getItem("id_token");
  }
}

const auth = new Auth();

export default auth;