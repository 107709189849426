import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import cookies from 'js-cookie'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  InputGroupAddon,
  InputGroup,
  Input,
  Form,
  NavLink,
  Row,
  Col,
  Container,
  Button,
  FormControl,
  Navbar,
  Nav,
  NavItem,
  NavDropdown,
  Modal
} from 'react-bootstrap';

import UserService from "../../services/UserService";

import { FaMapMarked as MapIcon, FaPowerOff as LogoutIcon, FaUserEdit as UserProfileIcon, FaChevronDown as ArrowDownIcon, FaChevronUp as ArrowUpIcon, FaLayerGroup as ModuleIcon, FaUserCog as RoleIcon, FaLanguage  as LanguageIcon, FaListUl as CategoryIcon, FaUser as UserIcon } from "react-icons/fa";

import { ListGroup, ListGroupItem } from "reactstrap";

import sAccount from "../Notifications/notifications-demo/ListGroup.module.scss";

import settingsIcon from "../../images/settings.svg";
import logoutIcon from "../../images/logout.svg";
import accountIcon from "../../images/account.svg";

import cx from "classnames";
import Notifications from "../Notifications";
import { logoutUser } from "../../actions/user";
import logo from "../../images/apm-terminals-logo-2.png";
import {
  toggleSidebar,
  openSidebar,
  closeSidebar,
  changeActiveSidebarItem,
} from "../../actions/navigation";



import userAvatar from "../../images/userAvatar.png";
import search from "../../images/search.svg";
import notify from "../../images/notify.svg";
import lightNotify from "../../images/light-notify.svg";
import messages from "../../images/messages.svg";
import lightMessages from "../../images/messages-filled.svg";
import arrowUnactive from '../../images/Arrow 6.svg'
import arrowActive from '../../images/Arrow 5.svg'

import s from "./Header.module.scss"; // eslint-disable-line css-modules/no-unused-class

class Header extends React.Component {
  static propTypes = {
    sidebarOpened: PropTypes.bool.isRequired,
    sidebarStatic: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.retrieveUser = this.retrieveUser.bind(this);
    this.retrieveUserAvatar = this.retrieveUserAvatar.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.switchSidebar = this.switchSidebar.bind(this);
    this.toggleNotifications = this.toggleNotifications.bind(this);
    this.toggleMessages = this.toggleMessages.bind(this);
    this.toggleAccount = this.toggleAccount.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.doLogout = this.doLogout.bind(this);
    this.goToProfile = this.goToProfile.bind(this);
    this.changeArrowImg = this.changeArrowImg.bind(this);
    this.changeArrowImgOut = this.changeArrowImgOut.bind(this);

    this.state = {
      menuOpen: false,
      notificationsOpen: false,
      loading: true,
      messagesOpen: false,
      accountOpen: false,
      iframeVisible: false,
      iframeHidden: true,
      loading: true,
      avatar: null,
      user: null,
      arrowIconUp: false,
      notificationsTabSelected: 1,
      focus: false,
      showNewMessage: false,
      hideMessage: true,
      run: true,
      arrowImg: arrowActive
    };

    this.iframeRef = React.createRef();
    document.addEventListener("keydown", this.handleKeyDown);
    window.addEventListener("popstate", this.handlePopState);

    // Agregar el event listener para el cambio en el modo de pantalla completa
    document.addEventListener("fullscreenchange", this.handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", this.handleFullscreenChange);
    document.addEventListener("mozfullscreenchange", this.handleFullscreenChange);
    document.addEventListener("MSFullscreenChange", this.handleFullscreenChange);

  }

  componentWillUnmount() {
    // Eliminar el event listener al desmontar el componente
    document.removeEventListener("keydown", this.handleKeyDown);
    window.removeEventListener("popstate", this.handlePopState);
    // Eliminar el event listener para el cambio en el modo de pantalla completa
    document.removeEventListener("fullscreenchange", this.handleFullscreenChange);
    document.removeEventListener("webkitfullscreenchange", this.handleFullscreenChange);
    document.removeEventListener("mozfullscreenchange", this.handleFullscreenChange);
    document.removeEventListener("MSFullscreenChange", this.handleFullscreenChange);
  }

  // Manejar el evento de cambio en el modo de pantalla completa
  handleFullscreenChange = () => {
    // Verificar si el documento ya no está en modo de pantalla completa
    if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
      // El iframe ya no está en modo de pantalla completa, ocultarlo
      this.setState({ iframeHidden: true });
    }
  };

  // Manejar el evento de presionar una tecla
  handleKeyDown = (event) => {
    if (event.keyCode === 27 || event.keyCode === 37) {
      // Si la tecla presionada es Esc (código 27), ocultar el iframe
      this.setState({ iframeHidden: true });
      try{
        this.exitFullscreen();
      }catch(e){
      }

    }
  };

  // Manejar el evento de retroceso en la historia del navegador
  handlePopState = () => {
    // Ocultar el iframe al retroceder en la historia
    this.setState({ iframeHidden: true });
    try{
        this.exitFullscreen();
    }catch(e){
    }

  };

  exitFullscreen = () => {

    try{
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
    }catch(e){
    }

    this.setState({
      iframeVisible: false,
      iframeHidden: true,
    });
  };

  handleMapLinkClick = () => {
    window.location.href = "/induccion/app/map";
  };

  handleMapClick = () => {
    // Cambia el estado para activar la pantalla completa del mapa
    this.setState({
      iframeVisible: true,
      iframeHidden: false,
    });

    const iframe = this.iframeRef.current;

    if (iframe && iframe.requestFullscreen) {
      iframe.requestFullscreen();
    } else if (iframe && iframe.mozRequestFullScreen) {
      iframe.mozRequestFullScreen();
    } else if (iframe && iframe.webkitRequestFullscreen) {
      iframe.webkitRequestFullscreen();
    } else if (iframe && iframe.msRequestFullscreen) {
      iframe.msRequestFullscreen();
    }
  };

  componentDidMount() {
    this.retrieveUser();
  }

  toggleArrowIcon() {
    this.setState((prevState) => ({
      arrowIconUp: !prevState.arrowIconUp,
    }));
  }

  retrieveUser() {

    UserService.getCurrentUser()
    .then(response => {

      this.setState({
        user: response.data
      });

      this.retrieveUserAvatar(response.data.id);
      console.log(response.data);

    })
    .catch(e => {
      if (e.response != null && e.response.status != null && e.response.status === 403) {
        console.log('unauthorized, logging out ...');
        this.setState ({
          open: true,
          authenticated: false,
          loading: true
        })
      }else{
           this.setState ({
               loading: true,
               error: e.response
           })
       }
    });
  }

  retrieveUserAvatar(id) {

    var self = this;

    UserService.getAvatar(id)
      .then(response => {

        var avatar = response.data;
        var avatarCheck = cookies.get('avatarCheck_'+this.state.user.nationalId) ? cookies.get('avatarCheck_'+this.state.user.nationalId) : "false";

        self.setState({
          avatar: avatar.data,
          avatarCheck: avatarCheck,
          loading: false
        });

      })
      .catch(e => {
        if (e.response != null && e.response.status != null && e.response.status === 403) {
          console.log('unauthorized, logging out ...');
          self.setState ({
            open: true,
            avatarCheck: cookies.get('avatarCheck_'+this.state.user.nationalId) ? cookies.get('avatarCheck_'+this.state.user.nationalId) : "false",
            loading: false,
            authenticated: false
          })
        }else{
            self.setState({
              avatar: null,
              loading: false
            });
        }
      });
  }

  toggleFocus = () => {
    this.setState({ focus: !this.state.focus });
  };

  toggleNotifications() {
    this.setState({
      notificationsOpen: !this.state.notificationsOpen,
    });
  }

  toggleMessages() {
    this.setState({
      messagesOpen: !this.state.messagesOpen,
    });
  }

  toggleAccount() {
    this.setState({
      accountOpen: !this.state.accountOpen,
    });
  }

  goToProfile(id) {

     var d = new Date();
     d.setHours(0,0,0,0);
     d.setDate(d.getDate() + 1);

     var nationalId = this.state.user.nationalId;

     cookies.set('avatarCheck_'+nationalId, "true", { expires: d })

     this.setState ({
        avatarCheck: "true"
     })

     window.location = "/app/edit-user/"+id;
  }

  closeAvatarModal() {

     var d = new Date();
     d.setHours(0,0,0,0);
     d.setDate(d.getDate() + 1);

     var nationalId = this.state.user.nationalId;

     cookies.set('avatarCheck_'+nationalId, "true", { expires: d })

     this.setState ({
        avatarCheck: "true"
     })
  }

  doLogout() {
    this.props.dispatch(logoutUser());
  }

  changeArrowImg() {
    this.setState({
      arrowImg: arrowUnactive
    })
  }

  changeArrowImgOut() {
    this.setState({
      arrowImg: arrowActive
    })
  }

  // collapse/uncolappse
  switchSidebar() {
    if (this.props.sidebarOpened) {
      this.props.dispatch(closeSidebar());
      this.props.dispatch(changeActiveSidebarItem(null));
    } else {
      const paths = this.props.location.pathname.split("/");
      paths.pop();
      this.props.dispatch(openSidebar());
      this.props.dispatch(changeActiveSidebarItem(paths.join("/")));
    }
  }

  // tables/non-tables
  toggleSidebar() {
    this.props.dispatch(toggleSidebar());
    if (this.props.sidebarStatic) {
      localStorage.setItem("staticSidebar", "false");
      this.props.dispatch(changeActiveSidebarItem(null));
    } else {
      localStorage.setItem("staticSidebar", "true");
      const paths = this.props.location.pathname.split("/");
      paths.pop();
      this.props.dispatch(changeActiveSidebarItem(paths.join("/")));
    }
  }

  popupClose(event) {
    return false;
  }

  doLogout(){
      localStorage.removeItem("id_token");
      window.location.reload();
  }

  toggleMenu() {
    this.setState({
      menuOpen: !this.state.menuOpen,
    });
  }

  render() {

    const { focus, user, avatar, arrowIconUp, iframeVisible } = this.state;
    const { openUsersList } = this.props;

    var modal;

    if(this.state.loading == false && this.state.avatar == null && this.state.avatarCheck == "false"){

        modal =
                (<Modal
                    backdrop="static"
                    keyboard={false}
                    show={true}
                    onHide={(_, reason) => {this.popupClose(reason)}}>
                    <Modal.Header closeButton>
                      <Modal.Title>{this.context.t('avatar.title')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div classname="form-group account-btn text-center m-t-10">
                            <Row>
                                <Col xs={12} lg={6}>
                                    <button classname="btn btn-lg button button--success" tabIndex="2" onClick={() => this.goToProfile(user.id)}>{this.context.t('yes')}</button>
                                    <br/><br/>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <button classname="btn btn-lg button button--default" tabIndex="2" onClick={() => this.closeAvatarModal()}>{this.context.t('no')}</button>
                                    <br/><br/>
                                </Col>
                            </Row>
                        </div>
                    </Modal.Body>
                  </Modal>
            );
    }

    var currentUser = (<Nav id="logoutMax"><NavDropdown.Item href="/induccion/app/modules" onClick={() => this.doLogout()}>Salir</NavDropdown.Item></Nav>);
    var avatarBadge = "";
    var toggle =  "";
    var userNav = "";
    const arrowIcon = arrowIconUp ? (
          <ArrowUpIcon style={{ marginTop: "14px" }} /> // Aplica el estilo marginTop al ArrowUpIcon
        ) : (
          <ArrowDownIcon style={{ marginTop: "14px" }} /> // Aplica el estilo marginTop al ArrowDownIcon
        );

    if (this.state.loading == false) {
        const firstUserLetter = (() => {
          if (user.firstName && user.lastName) {
            return user.firstName.trim().charAt(0).toUpperCase()+""+user.lastName.trim().charAt(0).toUpperCase();
          } else if (user.username) {
            return user.username.trim().charAt(0).toUpperCase();
          } else if (user.email) {
            return user.email.trim().charAt(0).toUpperCase();
          } else {
            return 'P';
          }
        })();
      currentUser = (<Nav id="logoutMax" style={{ marginRight: "-13px"}}>
                         <NavDropdown eventkey={1}
                             title={
                                 <div className="pull-left">
                                     <span className={`${s.avatar} rounded-circle thumb-sm float-left mr-2 avatar-color`}>
                                       {avatar ? (
                                         <img src={avatar} alt="..." />
                                       ) : (
                                         <span>{firstUserLetter}</span>
                                       )}
                                     </span>
                                 </div>
                             }
                             id="basic-nav-dropdown"
                             drop="down" // Configura el desplazamiento hacia abajo (down)
                                   alignRight={true} // Configura alineación a la izquierda (left)
                                   >
                             <NavDropdown.Item href={"/induccion/app/edit-user/"+user.id}><UserIcon/>  {this.context.t('profile')}</NavDropdown.Item>
                             <NavDropdown.Item onClick={() => this.doLogout()}><LogoutIcon/> {this.context.t('logout')}</NavDropdown.Item>
                         </NavDropdown>
                     </Nav>);

      userNav = (<Nav.Link className="onlyMobile" href={"/induccion/app/edit-user/"+user.id}><UserIcon/>  {this.context.t('profile')}</Nav.Link>);

      avatarBadge = (<div className="pull-left">
                                                               <span className={`${s.avatar} rounded-circle thumb-sm float-left mr-2 avatar-color`}>
                                                                 {avatar ? (
                                                                   <img src={avatar} alt="..." />
                                                                 ) : (
                                                                   <span>{firstUserLetter}</span>
                                                                 )}
                                                               </span>
                                                           </div>);
    }

    const navDropdownTitle = (<div><UserIcon/> Usuarios</div>);

    return (

        <Navbar bg="light" expand="lg" className="header-container">
          {toggle}
          {modal}
          <Container fluid style={{paddingRight: "20px", paddingLeft: "20px"}}>
            <Navbar.Brand href="/induccion/app/modules">
                <img src={logo} alt="logo" className={s.logoStyle} style={{height: "55px"}}/>
                <iframe
                  title="Map"
                  ref={this.iframeRef}
                  className="iframe-centered"
                  style={{ visibility: this.state.iframeHidden ? "hidden" : "visible" }}
                  src="https://app.soma.solutions/apm/3d-map/"
                  allowFullScreen
                />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll">
                {avatarBadge}
                 <span onClick={() => this.toggleArrowIcon()}  style={{ cursor: "pointer" }}>
                    {arrowIcon}
                 </span>
            </Navbar.Toggle>
            <Navbar.Collapse id="navbarScroll">
              <hr className="onlyMobile"/>
              <Nav
                className="me-auto my-2 my-lg-0"
                style={{ maxHeight: '900px'}}
                navbarScroll
              >
                <Nav.Link href="/induccion/app/modules"><ModuleIcon/> {this.context.t('header.modules')}</Nav.Link>
                <Nav.Link onClick={this.handleMapLinkClick}>
                  <MapIcon /> {this.context.t("header.map")}
                </Nav.Link>
                {userNav}
              </Nav>
              <div className={`ml-auto ${s.fullVersionBtn}`}/>

              <Form className={`d-md-down-none`} inline>
              </Form>
              <Nav className="onlyMobile">
                 <Nav.Link onClick={() => this.doLogout()}><LogoutIcon/> {this.context.t('logout')}</Nav.Link>
              </Nav>
              {currentUser}
            </Navbar.Collapse>
          </Container>
        </Navbar>

    );
  }
}

Header.contextTypes = {
  t: PropTypes.func.isRequired
}

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    sidebarStatic: store.navigation.sidebarStatic,
    lang: store.i18nState.lang
  };
}

export default withRouter(connect(mapStateToProps)(Header));
