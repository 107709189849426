import axios from "axios";


const baseURL= process.env.APP_TERMINAL_API_URL+ "/api/v1";

class RolesDataService {
   getAll() {
      return axios.get(baseURL+'/roles', {
       headers: {
        "Content-type": "application/json",
         'Authorization': localStorage.getItem('id_token')
       }
      });
    }

}

export default new RolesDataService();