exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "/**\n * Customized version of bootstrap using variables from _variables.scss.\n * This file is loaded via separate loader thus allowing to use original bootstrap classes (e.g. .btn-default) through out the app.\n */\n/*\n * Typography\n * ======================================================================== */\n/**\n * Custom application mixins available through out the app\n */\n/* Chat Scrollbar */\n.Layout_root__AnDLA {\n  height: 100%;\n  position: relative;\n  left: 0;\n  transition: left 0.3s ease-in-out; }\n\n.Layout_wrap__bca7h {\n  position: relative;\n  min-height: 100vh;\n  display: flex;\n  margin-left: 284px;\n  flex-direction: column;\n  right: 0;\n  transition: left 0.3s ease-in-out, margin-left 0.3s ease-in-out; }\n  @media (max-width: 767.98px) {\n    .Layout_wrap__bca7h {\n      margin-left: 0px; } }\n\n.Layout_sidebarClose__1aDwI div.Layout_wrap__bca7h {\n  left: 0; }\n\n.Layout_sidebarStatic__3u5yP .Layout_wrap__bca7h {\n  left: 0;\n  margin-left: 284px; }\n\n.Layout_content__309u4 {\n  position: relative;\n  flex-grow: 1;\n  padding: 40px 40px 60px;\n  background-color: #FAFAFA; }\n  @media (max-width: 767.98px) {\n    .Layout_content__309u4 {\n      padding: 25px 25px 70px; } }\n  @media (min-width: 576px) {\n    .Layout_content__309u4 {\n      -webkit-user-select: auto !important;\n              user-select: auto !important; } }\n\n.Layout_contentFooter__hCBTL {\n  position: absolute;\n  bottom: 15px;\n  color: #798892; }\n", ""]);

// Exports
exports.locals = {
	"root": "Layout_root__AnDLA",
	"wrap": "Layout_wrap__bca7h",
	"sidebarClose": "Layout_sidebarClose__1aDwI",
	"sidebarStatic": "Layout_sidebarStatic__3u5yP",
	"content": "Layout_content__309u4",
	"contentFooter": "Layout_contentFooter__hCBTL"
};