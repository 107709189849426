import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect, BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import {setLanguage} from 'redux-i18n'
import cookies from 'js-cookie'

/* eslint-disable */
import ErrorPage from '../pages/error';
/* eslint-enable */

import '../styles/theme.scss';
import LayoutComponent from '../components/Layout';
import Login from '../pages/login';
import Register from '../pages/register';
import ResetPassword from '../pages/login/ResetPassword';
import ContactUs from '../pages/login/ContactUs';
import ConfirmRegister from '../pages/login/ConfirmRegister';
import NewUser from '../pages/login/NewUser';
import { logoutUser } from '../actions/user';

import auth from '../Auth';

const PrivateRoute = ({dispatch, component, ...rest }) => {

    var isAuthenticated = auth.isAuthenticated();

    if (isAuthenticated) {
        return ( // eslint-disable-line
            <Route {...rest} render={props => (React.createElement(component, props))}/>
        );
    } else {
        dispatch(logoutUser());
        return (<Redirect to="/login"/>)
    }
};

const CloseButton = ({closeToast}) => <i onClick={closeToast} className="la la-close notifications-close"/>

class App extends React.PureComponent {

  constructor (props) {
    super(props)
    const currentLanguageCode = cookies.get('i18lang') || 'ES';
    this.props.dispatch(setLanguage(currentLanguageCode))
  }

  render() {
    return (
        <div>
            <ToastContainer
                autoClose={5000}
                hideProgressBar
                closeButton={<CloseButton/>}
            />
            <BrowserRouter basename="/induccion">
                <Switch>
                    <Route path="/" exact render={() => <Redirect to="/app/main"/>}/>
                    <Route path="/app" exact render={() => <Redirect to="/app/main"/>}/>
                    <PrivateRoute path="/app" dispatch={this.props.dispatch} component={LayoutComponent}/>
                    <Route path="/register" exact component={Register}/>
                    <Route path="/resetPassword" exact component={ResetPassword}/>
                    <Route path="/contactUs" exact component={ContactUs}/>
                    <Route path="/userRegister" exact component={NewUser}/>
                    <Route exact path='/confirmRegister/:token' component={
                      (props) => <ConfirmRegister token={props.match.params.token}/>
                    } />
                    <Route path="/login" exact component={Login}/>
                    <Route path="/error" exact component={ErrorPage}/>
                    <Route component={ErrorPage}/>
                </Switch>
            </BrowserRouter>
        </div>

    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  lang: state.i18nState.lang
});

export default connect(mapStateToProps)(App);
