import React from "react";
import { getAnalytics, logEvent } from "firebase/analytics";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FaExpand as ExpandIcon } from "react-icons/fa";

class Maps extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      maxHeight: 0,
      maxWidth: 0,
      originalAspectRatio: 512 / 288,
    };

    this.iframeRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateMapSize);
    this.updateMapSize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateMapSize);
  }

  updateMapSize = () => {
    const height = window.innerHeight;
    const width = window.innerWidth;
    const aspectRatio = 512 / 288;

    if (width / height > aspectRatio) {
        this.calculateWithHeight();
    } else {
        this.calculateWithWidth()
    }
  };

  calculateWithWidth() {
    const height = window.innerHeight;
    const width = window.innerWidth;
    const maxWidth = width - 70;
    const aspectRatio = 512 / 288;
    let maxHeight = maxWidth / aspectRatio;

    this.setState({
        maxHeight: maxHeight,
        maxWidth: maxWidth,
        originalAspectRatio: aspectRatio,
    });
  }

  calculateWithHeight() {
    const height = window.innerHeight;
    const width = window.innerWidth - 70;
    const maxHeight = height - 120;
    const aspectRatio = 512 / 288;
    const maxWidth = maxHeight * aspectRatio;

    this.setState({
        maxHeight: maxHeight,
        maxWidth: maxWidth,
        originalAspectRatio: aspectRatio,
    });
  }

  handleMaximizeClick = () => {
    const iframe = this.iframeRef.current;

    if (iframe && iframe.requestFullscreen) {
      iframe.requestFullscreen();
    } else if (iframe && iframe.mozRequestFullScreen) {
      iframe.mozRequestFullScreen();
    } else if (iframe && iframe.webkitRequestFullscreen) {
      iframe.webkitRequestFullscreen();
    } else if (iframe && iframe.msRequestFullscreen) {
      iframe.msRequestFullscreen();
    }
  };

  render() {
    const analytics = getAnalytics();
    logEvent(analytics, "maps_page");

    const { maxWidth, maxHeight } = this.state;

    return (
      <div style={{ width: "100%", textAlign: "center", position: "relative" }}>
        <iframe
          ref={this.iframeRef}
          title="Map"
          style={{
            border: "none",
            width: maxWidth + "px",
            height: maxHeight + "px",
          }}
          src="https://app.soma.solutions/apm/3d-map/"
        />
      </div>
    );
  }
}

Maps.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect((state) => ({
  lang: state.i18nState.lang,
}))(Maps);
