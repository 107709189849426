import React from "react";
import PropTypes from "prop-types";
import { withRouter, Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import {Alert, Button, Label, Input, FormGroup, Form} from 'react-bootstrap';
import Widget from "../../components/Widget";
import { loginUser } from "../../actions/user";
import s from './Login.module.scss';
import signinImg from "../../images/signinImg.svg";
import logo from "../../images/login_image.jpeg";
import img1 from "../../images/Vector-1.svg";
import img2 from "../../images/Vector-2.svg";
import img3 from "../../images/Vector-3.svg";
import img4 from "../../images/Vector-4.svg";
import UserService from "../../services/UserService";
import auth from '../../Auth';
import { FaTimesCircle } from "react-icons/fa";

import { getAnalytics, logEvent } from "firebase/analytics";

class ResetPassword extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  static isAuthenticated(token) {
    if (token) return true;
  }

  constructor(props) {
    super(props);

    this.state = {
      email: ""
    };

    this.sendContactMail = this.sendContactMail.bind(this);
  }

  handleChange = (field, event) => {

    if (field === 'enabled') {
      this.setState({
        [field]: event.target.value === 'true'
      })
    } else {
      this.setState({
        [field]: event.target.value
      })
    }
  }

  sendContactMail(e) {
    e.preventDefault();

    this.setState ({
        loading: true
    });

    const contactForm = {
        "email" : this.state.email,
        "phone" : this.state.phone,
        "subject" : this.state.subject,
        "message" : this.state.message
    }

    UserService.sendContactMail(contactForm).then(response => {
        const analytics = getAnalytics();
        logEvent(analytics, 'contact_form', contactForm);
        this.setState ({
            loading: false,
            errorLogin: null,
            errorKey: null,
            success: true
        })

    })
    .catch(e => {
        this.setState ({
            loading: false,
            errorLogin: e.response,
            errorKey: (e.response != null && e.response.data != null && e.response.data.error_key != null) ? e.response.data.error_key : null,
            errorNetLogin: e.message
        })
    });
  }

  render() {
    const { from } = this.props.location.state || { from: { pathname: '/app' } }; // eslint-disable-line

    var isAuthenticated = auth.isAuthenticated();

    // cant access login page while logged in
    if (isAuthenticated) {
      return (
          <Redirect to={from} />
      );
    }

    let loading;

    if (this.state.loading) {
      loading = (
        <div>
          {this.context.t('loading')}
        </div>
      )
    }

    let errorLogin

    if (this.state.errorNetLogin) {
      errorLogin = <Alert variant="danger" style={{margin: "20px"}}>
           {this.context.t('login.error')}
        </Alert>;
    }

    if (this.state.errorLogin) {
      var errorKey = this.state.errorKey;
      var errorLink = this.state.errorLink;

      errorLogin = <Alert variant="danger" style={{margin: "20px"}}>
                <strong><FaTimesCircle/></strong> {this.context.t(errorKey)}
        </Alert>;
    }

    let sent = "";

    if (this.state.success) {
      sent = <Alert variant="success" style={{margin: "20px"}}>
           {this.context.t('contact_us.success')}
        </Alert>;
    }

    return (

        <section>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        {errorLogin}
                        {sent}
                        {loading}
                        <div className="wrapper-page">

                            <div className="m-t-40 card-box">
                                <div className="text-center">
                                    <h2 className="text-uppercase m-t-0 m-b-30">
                                        <a className="text-success"> <span><img src={logo} alt="" height="70&quot;"/></span>
                                        </a>
                                    </h2>
                                    <h5 className="text-center m-b-20">{this.context.t('contact_us.title')}</h5>
                                </div>
                                <div className="account-content">
                                    <div className="text-center m-b-20">
                                        <p className="text-muted m-b-0 line-h-24" style={{fontSize: "14px", marginBottom: "20px"}}>
                                            {this.context.t('contact_us.text')}
                                        </p>
                                    </div>
                                    <form method="POST" className="form-horizontal" onSubmit={this.sendContactMail}>

                                        <Form.Group controlId='email'>
                                            <Form.Label>{this.context.t('contact_us.email')} <span className="text-danger">*</span></Form.Label>
                                            <Form.Control type='email' value={this.state.email} onChange={e => {this.handleChange('email', e)}} required disabled={this.state.success}/>
                                        </Form.Group>

                                        <Form.Group controlId='phone'>
                                            <Form.Label>{this.context.t('contact_us.phone')} <span className="text-danger">*</span></Form.Label>
                                            <Form.Control type='text' value={this.state.phone} onChange={e => {this.handleChange('phone', e)}} required disabled={this.state.success}/>
                                        </Form.Group>

                                        <Form.Group controlId='subject'>
                                            <Form.Label>{this.context.t('contact_us.subject')} <span className="text-danger">*</span></Form.Label>
                                            <Form.Control type='text' value={this.state.subject} onChange={e => {this.handleChange('subject', e)}} required disabled={this.state.success}/>
                                        </Form.Group>

                                        <Form.Group controlId='message'>
                                            <Form.Label>{this.context.t('contact_us.message')} <span className="text-danger">*</span></Form.Label>
                                            <Form.Control as="textarea" rows={3} maxLength="255" value={this.state.message} onChange={e => {this.handleChange('message', e)}} required disabled={this.state.success}/>
                                        </Form.Group>

                                        <div className="form-group account-btn text-center m-t-10">
                                            <div className="col-12">
                                                <button className="btn btn-lg btn-block button button--primary" tabIndex="2" type="submit" disabled={this.state.loading || this.state.success}>{this.context.t('contact_us.send')}</button>
                                                <br/><br/>
                                            </div>
                                        </div>
                                    </form>

                                    <div className="clearfix"></div>

                                </div>
                            </div>
                            <div className="row m-t-50">
                                <div className="col-sm-12 text-center">
                                    <p className="text-muted">{this.context.t('contact_us.back')}<a href="/induccion/login" className="text-dark m-l-5">Login</a></p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
  }
}

ResetPassword.contextTypes = {
  t: PropTypes.func.isRequired
}

function mapStateToProps(state) {
  return {
    isFetching: state.auth.isFetching,
    isAuthenticated: state.auth.isAuthenticated,
    errorMessage: state.auth.errorMessage,
    lang: state.i18nState.lang
  };
}

export default withRouter(connect(mapStateToProps)(ResetPassword));
