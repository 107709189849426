import axios from "axios";

const baseURL= process.env.APP_TERMINAL_API_URL+ "/api/v1";

class QuestionnaireDataService {

  getAll(moduleId, countryCode) {

    return axios.get(baseURL+`/questionnaires?moduleId=${moduleId}&countryCode=${countryCode}`, {
     headers: {
      "Content-type": "application/json",
       'Authorization': localStorage.getItem('id_token')
     }
    });
  }

  get(id) {
    return axios.get(baseURL+`/questionnaires/${id}`, {
     headers: {
      "Content-type": "application/json",
       'Authorization': localStorage.getItem('id_token')
     }
    });
  }

  create(data) {
    return axios.post(baseURL+'/questionnaires', data, {
     headers: {
       "Content-type": "application/json",
       'Authorization': localStorage.getItem('id_token')
     }
    });
  }

  update(id, data) {

    return axios.put(baseURL+`/questionnaires/${id}`, data, {
     headers: {
       "Content-type": "application/json",
       'Authorization': localStorage.getItem('id_token')
     }
    });
  }

  delete(id) {
    return axios.delete(baseURL+`/questionnaires/${id}`, {
     headers: {
      "Content-type": "application/json",
       'Authorization': localStorage.getItem('id_token')
     }
    });
  }
}

export default new QuestionnaireDataService();