export const translations = {
    "GB": {
        "login.user"                    :   "National ID",
        "login.password"                :   "Password",
        "login.login"                   :   "Login",
        "login.forgot_password"         :   "Forgot your password?",
        "login.have_account"            :   "You do not have an account?",
        "login.check_in"                :   "Check in",
        "login.contact_us"              :   "Contact Us for Help",

        "login.error"                   :   "Username or password incorrect",

        "play.video"                    :   "Play Video",

        "field.required"                :   "Please complete this field",
        "answer.required"               :   "please select an answer",

        "user_not_exist"                :   "Please check the information entered",
        "user_inactive"                 :   "Access denied. Your account has not yet been verified. Resend account verification email",
        "user_disabled"                 :   "Access denied. Contact us through the help section please",
        "user_already_exist"            :   "This national id is already registered. Contact us through the help section please",
        "password_incorrect"            :   "Username or password incorrect",
        "necessary_roles"               :   "Username or password incorrect",

        "click.here"                    :   "Click Here",

        "yes"                           :   "YES",
        "no"                            :   "NO",

        "date.format"                   :   "MM/DD/YYYY HH:mm",

        "modules.name"                  :   "Name",
        "modules.finish_date"           :   "Finish Date",
        "modules.expiration_date"       :   "Expiration Date",
        "modules.status"                :   "Status",
        "modules.approved"              :   "APPROVED",
        "modules.disapproved"           :   "DISAPPROVED",
        "modules.pending"               :   "PENDING",
        "modules.expired"               :   "EXPIRED",
        "modules.make"                  :   "MAKE",
        "modules.correct_answers"       :   "Correct answers",
        "modules.of"                    :   "of",
        "modules.view"                  :   "View",
        "modules.download"              :   "DOWNLOAD PROOF",
        "modules.continue"              :   "CONTINUE",
        "modules.send"                  :   "SEND PROOF BY MAIL",
        "save"                          :   "SAVE",
        "next"                          :   "NEXT",
        "certificate_of"                :   "Certificate for Category",

        "sign.title"                    :   "Enter your password to sign the module",
        "sign.button"                   :   "sign",

        "profile"                       :   "Profile",
        "accept"                        :   "Accept",
        "avatar.title"                  :   "You didn't upload your profile picture, you want to do it right now",
        "logout"                        :   "Logout",

        "reset_password.user"           :   "National ID",
        "reset_password.text"           :   "Enter your national ID and we will send you an email with instructions to reset your password.",
        "reset_password.send"           :   "Send",
        "reset_password.back"           :   "Go Back to ",
        "reset_password.error"          :   "Please check the information entered",

        "contact_us.title"              :   "Contact Us",
        "contact_us.text"               :   "Please... enter the necessary fields and press send",
        "contact_us.email"              :   "Email",
        "contact_us.phone"              :   "Cell Phone",
        "contact_us.subject"            :   "Subject",
        "contact_us.message"            :   "Message",
        "contact_us.success"            :   "The message has been received, we will reply as soon as possible",
        "contact_us.send"               :   "Send",
        "contact_us.back"               :   "Back to ",

        "user.title"                    :   "New User ",
        "user.back"                     :   "Go Back to ",
        "user.send"                     :   "Send ",
        "user.name"                     :   "Name ",
        "user.second_name"              :   "Second Name ",
        "user.last_name"                :   "Last Name ",
        "user.second_last_name"         :   "Second Last Name ",
        "user.national_id"              :   "National ID ",
        "user.national_id.title"        :   "National ID is required (ONLY NUMBERS AND LETTERS)",
        "user.password"                 :   "Password ",
        "user.confirm_password"         :   "Confirm Password ",
        "user.confirm_password_error"   :   "Passwords do not match",
        "user.phone"                    :   "Phone ",
        "user.photo"                    :   "\xa0\xa0\xa0\xa0\xa0\xa0\xa0 Edit ",
        "user.email"                    :   "Email ",
        "user.category"                 :   "Category ",
        "user.company"                  :   "Company ",

        "confirm_register.title"        :   "Confirm Register ",

        "registration_success.title"    :   "THANK YOU FOR REGISTERING",
        "registration_success.body"     :   "Please check your email both the main tray or, failing that, the SPAM tray where you will find the instructions to continue with the registration process.",

        "mail.fail"                     :   "Could not send mail",
        "mail.success"                  :   "The mail was sent to the mailbox.",
        "mail.check"                    :   "Check your e-mail",


        "header.modules"                :   "Modules",
        "header.module"                 :   "Module",
        "header.map"                    :   "Interactive Map",

        "lang.select"                   :   "Select Language",

        "loading"                       :   "Loading... please wait a moment",
        "loading.video"                 :   "Loading Video...",
        "video.noavailable"             :   "No video available",

        "error.unexpected"              :   "There was an unexpected error",
        "could_not_record"              :   "Could not record",


        "session.expired"               :   "Session Expired",
        "session.msg"                   :   "Your session has expired.",
        "session.redirect"              :   "You will be redirected to the Login page",

        'maximize'                      :   "Maximize",

        'questions.empty'               :   "No questions loaded",
        'answers.empty'                 :   "No answers loaded",

        "confirm_register.error"        :   "Oops.. There was an error the user could not be activated",
        "confirm_register.success"      :   "User has been activated",

        "table.all"                     :   "Todos",
        "table.sProcessing"             :   "Processing...",
        "table.sLengthMenu"             :   "Show _MENU_ entries",
        "table.sZeroRecords"            :   "No matching records found",
        "table.sEmptyTable"             :   "No data available in table",
        "table.sInfo"                   :   "Showing _START_ to _END_ of _TOTAL_ entries",
        "table.sInfoEmpty"              :   "Showing 0 to 0 of 0 entries",
        "table.sInfoFiltered"           :   "(filtered from _MAX_ total entries)",
        "table.sSearch"                 :   "Search",
        "table.sLoadingRecords"         :   "Loading...",
        "table.sFirst"                  :   "First",
        "table.sLast"                   :   "Last",
        "table.sNext"                   :   "Next",
        "table.sPrevious"               :   "Previous",
        "table.sSortAscending"          :   ": activate to sort column ascending",
        "table.sSortDescending"         :   ": activate to sort column descending",

    },
    "ES": {
        "login.user"                    :   "Número de Documento",
        "login.password"                :   "Contraseña",
        "login.login"                   :   "Ingresar",
        "login.forgot_password"         :   "Olvidó su contraseña?",
        "login.have_account"            :   "No tienes cuenta?",
        "login.check_in"                :   "Registrarse",
        "login.error"                   :   "Usuario o contraseña incorrectos",
        "login.contact_us"              :   "Contactar por ayuda",

        "field.required"                :   "Por favor, completa este campo",
        "answer.required"               :   "Por favor, seleccione una respuesta",

        "play.video"                    :   "Ver Video",

        "user_not_exist"                :   "Por favor verifique la información ingresada",
        "user_inactive"                 :   "Acceso denegado. Tu cuenta no ha sido verificada aún. Reenviar email de verificación de cuenta.",
        "user_disabled"                 :   "Acceso denegado. Contactenos a través de la sección de ayuda por favor.",
        "user_already_exist"            :   "Ese documento ya se encuentra registrado en el sistema. Contactenos a través de la sección de ayuda por favor.",
        "password_incorrect"            :   "Username or password incorrect",
        "necessary_roles"               :   "Username or password incorrect",

        "click.here"                    :   "Haga Click Aquí",

        "certificate_of"                :   "Certificado de la categoria",

        "profile"                       :   "Perfil",
        "accept"                        :   "Aceptar",
        "logout"                        :   "Salir",

        "yes"                           :   "SI",
        "no"                            :   "NO",

        "mail.fail"                     :   "No se pudo enviar el mail",
        "mail.success"                  :   "El mail fue enviado a la casilla",
        "mail.check"                    :   "Revise su email",

        "avatar.title"                  :   "No cargo su Foto de perfil, desea hacerlo en este momento",

        "date.format"                   :   "DD/MM/YYYY HH:mm",

        "confirm_register.error"        :   "No se pudo activar",
        "confirm_register.success"      :   "Usuario activado",

        "sign.title"                    :   "Ingrese su contraseña para firmar el módulo",
        "sign.button"                   :   "firmar",
        "modules.name"                  :   "Nombre",
        "modules.finish_date"           :   "Fecha de Realización",
        "modules.expiration_date"       :   "Fecha de Expiración",
        "modules.status"                :   "Estado",
        "modules.approved"              :   "APROBADO",
        "modules.disapproved"           :   "DESAPROBADO",
        "modules.pending"               :   "PENDIENTE",
        "modules.expired"               :   "VENCIDO",
        "modules.make"                  :   "HACER",
        "modules.correct_answers"       :   "Respuestas correctas",
        "modules.of"                    :   "de",
        "modules.view"                  :   "Ver",
        "modules.download"              :   "DESCARGAR COMPROBANTE",
        "modules.send"                  :   "ENVIAR COMPROBANTE POR MAIL",
        "modules.continue"              :   "CONTINUAR",
        "save"                          :   "GRABAR",
        "next"                          :   "SIGUIENTE",

        "reset_password.user"           :   "Documento",
        "reset_password.text"           :   "Ingrese su DNI y le enviaremos un correo electrónico con instrucciones para restablecer su contraseña.",
        "reset_password.send"           :   "Enviar",
        "reset_password.back"           :   "Volver al ",
        "reset_password.error"          :   "Por favor verifique la información ingresada",

        "contact_us.title"              :   "Formulario de Contacto",
        "contact_us.text"               :   "Por favor... ingrese los campos necesarios y presione enviar",
        "contact_us.email"              :   "Email",
        "contact_us.phone"              :   "Celular",
        "contact_us.subject"            :   "Asunto",
        "contact_us.message"            :   "Mensaje",
        "contact_us.success"            :   "El mensaje ha sido recibido, le contestaremos a la brevedad",
        "contact_us.send"               :   "Enviar",
        "contact_us.back"               :   "Volver al ",

        "user.title"                    :   "Nuevo Usuario ",
        "user.back"                     :   "Volver al ",
        "user.send"                     :   "Enviar ",
        "user.name"                     :   "Nombre ",
        "user.second_name"              :   "Segundo Nombre ",
        "user.last_name"                :   "Apellido Paterno",
        "user.second_last_name"         :   "Apellido Materno ",
        "user.national_id"              :   "Documento ",
        "user.national_id.title"        :   "Documento es obligatorio (SOLO LETRAS Y NUMEROS)",
        "user.password"                 :   "Contraseña ",
        "user.confirm_password"         :   "Confirmar Contraseña ",
        "user.confirm_password_error"   :   "Las Contraseñas no coinciden",
        "user.phone"                    :   "Teléfono ",
        "user.photo"                    :   "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 Editar ",
        "user.email"                    :   "Email ",
        "user.category"                 :   "Categoria ",
        "user.company"                  :   "Empresa ",

        "confirm_register.title"        :   "Confirmación de Registro ",

        "registration_success.title"    :   "MUCHAS GRACIAS POR REGISTRARSE",
        "registration_success.body"     :   "Por favor verifique su email tanto la bandeja principal o en su defecto la de SPAM donde encontrará las instrucciones para poder continuar con el proceso de registración.",

        "header.modules"                :   "Módulos",
        "header.module"                 :   "Módulo",
        "header.map"                    :   "Mapa Interactivo",

        "lang.select"                   :   "Seleccionar Lenguaje",

        "loading"                       :   "Cargando... por favor aguarde un momento",
        "loading.video"                 :   "Cargando Video...",
        "video.noavailable"             :   "No hay video disponible",

        'maximize'                      :   "Maximizar",

        "error.unexpected"              :   "Hubo un error inesperado",
        "could_not_record"              :   "No se pudo Grabar",

        "session.expired"               :   "Sesión Expirada",
        "session.msg"                   :   "Su sesión ha expirado.",
        "session.redirect"              :   "Usted sera redirigido a la pagina de Login",

        'questions.empty'               :   "No hay preguntas cargadas",
        'answers.empty'                 :   "No hay respuestas cargadas",

        "table.all"                     :   "Todos",
        "table.sProcessing"             :   "Procesando...",
        "table.sLengthMenu"             :   "Mostrar _MENU_",
        "table.sZeroRecords"            :   "No se encontraron resultados",
        "table.sEmptyTable"             :   "Ningún dato disponible...",
        "table.sInfo"                   :   "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
        "table.sInfoEmpty"              :   "Mostrando registros del 0 al 0 de un total de 0 registros",
        "table.sInfoFiltered"           :   "(filtrado de un total de _MAX_ registros)",
        "table.sSearch"                 :   "Buscar",
        "table.sLoadingRecords"         :   "Cargando...",
        "table.sFirst"                  :   "Primero",
        "table.sLast"                   :   "Ultimo",
        "table.sNext"                   :   "Anterior",
        "table.sPrevious"               :   "Siguiente",
        "table.sSortAscending"          :   ": Activar para ordenar la columna de manera ascendente",
        "table.sSortDescending"         :   ": Activar para ordenar la columna de manera descendente",
    }
}