import React from "react";
import PropTypes from "prop-types";
import { withRouter, Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import {Alert, Button, Label, Input, FormGroup} from 'react-bootstrap';
import Widget from "../../components/Widget";
import { loginUser } from "../../actions/user";
import s from './Login.module.scss';
import signinImg from "../../images/signinImg.svg";
import logo from "../../images/login_image.jpeg";
import img1 from "../../images/Vector-1.svg";
import img2 from "../../images/Vector-2.svg";
import img3 from "../../images/Vector-3.svg";
import img4 from "../../images/Vector-4.svg";
import UserService from "../../services/UserService";
import auth from '../../Auth';
import { FaTimesCircle } from "react-icons/fa";

import { getAnalytics, logEvent } from "firebase/analytics";

var count = 0;

class ConfirmRegister extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  static isAuthenticated(token) {
    if (token) return true;
  }

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      sending: false,
      success: false
    };

  }

  _Mounted = false;

  changeEmail(event) {
    this.setState({ email: event.target.value });
  }

  componentDidMount() {

    if(count == 1){
        this.doActiveUser();
    }
    count++;
  }

  doActiveUser() {

   var self = this;

   var token = this.props.token;

   UserService.activeUser(token,this.props.lang).then(response => {
      const analytics = getAnalytics();
      logEvent(analytics, 'confirm_register');
       this.setState ({
           loading: false,
           success: true
       })
   })
   .catch(e => {
       if (e.response != null && e.response.status != null && e.response.status === 403) {
         console.log('unauthorized, logging out ...');
         self.setState ({
           success: false,
           loading: false,
           error: e.response,
           errorNet: e.message
         })
       }else{
            this.setState ({
                loading: false,
                success: false,
                error: e.response,
                errorNet: e.message
            })
        }
   });


  }


  render() {
    const { from } = this.props.location.state || { from: { pathname: '/app' } }; // eslint-disable-line

    let loading;

    if (this.state.loading) {
      loading = (
        <div>
         {this.context.t('loading')}
        </div>
      )
    }

    let error

    if (this.state.error) {
      error = <Alert variant="danger" style={{margin: "20px"}}>

        </Alert>;
    }

    if (this.state.errorNet) {
      error = <Alert variant="danger" style={{margin: "20px"}}>
               <strong><FaTimesCircle/></strong> {this.context.t('confirm_register.error')}
        </Alert>;
    }

    let active = "";

    if (this.state.success) {
      active = <Alert variant="success" style={{margin: "20px"}}>
           {this.context.t('confirm_register.success')}
        </Alert>;
    }

    return (

        <section>
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">

                        <div class="wrapper-page">

                            <div class="m-t-40 card-box">
                                <div class="text-center">
                                    <h2 class="text-uppercase m-t-0 m-b-30">
                                        <a class="text-success"> <span><img src={logo} alt="" height="70&quot;"/></span>
                                        </a>
                                    </h2>
                                </div>
                                <div class="text-center m-b-20">
                                    <h5 class="text-muted m-b-0 line-h-24">
                                        {this.context.t('confirm_register.title')}
                                    </h5>
                                </div>

                                <div class="account-content" style={{marginTop: "40px"}}>

                                    {error}
                                    {active}
                                    {loading}

                                    <div class="clearfix"></div>

                                </div>
                            </div>
                            <div class="row m-t-50">
                                <div class="col-sm-12 text-center">
                                    <p class="text-muted">{this.context.t('reset_password.back')}<a href="/induccion/login" class="text-dark m-l-5">Login</a></p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
  }
}

ConfirmRegister.contextTypes = {
  t: PropTypes.func.isRequired
}

function mapStateToProps(state) {
  return {
    isFetching: state.auth.isFetching,
    isAuthenticated: state.auth.isAuthenticated,
    errorMessage: state.auth.errorMessage,
    lang: state.i18nState.lang
  };
}

export default withRouter(connect(mapStateToProps)(ConfirmRegister));
