import axios from "axios";


const baseURL= process.env.APP_TERMINAL_API_URL+ "/api/v1";

class CategoriesDataService {

  getAll(status) {

      let url = baseURL+'/categories'+ ( (status && status != "") ? ("?status="+status) : "" );

      return axios.get(url, {
       headers: {
        "Content-type": "application/json",
         'Authorization': localStorage.getItem('id_token')
       }
      });
    }
  
    get(id) {
      return axios.get(baseURL+`/categories/${id}`, {
       headers: {
        "Content-type": "application/json",
         'Authorization': localStorage.getItem('id_token')
       }
      });
    }
  
    create(data) {
      return axios.post(baseURL+'/categories', data, {
       headers: {
         "Content-type": "application/json",
         'Authorization': localStorage.getItem('id_token')
       }
      });
    }
  
    update(id, data) {
  
      return axios.put(baseURL+`/categories/${id}`, data, {
       headers: {
         "Content-type": "application/json",
         'Authorization': localStorage.getItem('id_token')
       }
      });
    }
  
    delete(id) {
      return axios.delete(baseURL+`/categories/${id}`, {
       headers: {
        "Content-type": "application/json",
         'Authorization': localStorage.getItem('id_token')
       }
      });
    }
}

export default new CategoriesDataService();