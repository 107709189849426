exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "/**\n * Customized version of bootstrap using variables from _variables.scss.\n * This file is loaded via separate loader thus allowing to use original bootstrap classes (e.g. .btn-default) through out the app.\n */\n/*\n * Typography\n * ======================================================================== */\n/**\n * Custom application mixins available through out the app\n */\n/* Chat Scrollbar */\n.ErrorPage_errorPage__3xWNf {\n  height: 100vh; }\n  .ErrorPage_errorPage__3xWNf img {\n    position: absolute;\n    right: 0;\n    bottom: 0;\n    max-height: 80%; }\n    @media (max-width: 767.98px) {\n      .ErrorPage_errorPage__3xWNf img {\n        display: none; } }\n\n.ErrorPage_errorContainer__1n7L0 {\n  height: 100vh;\n  display: flex;\n  flex-direction: column;\n  justify-content: center; }\n\n.ErrorPage_errorBtn__2GhdA {\n  padding-left: 35px;\n  padding-right: 35px; }\n\n.ErrorPage_errorCode__2Loqq {\n  margin: 20px;\n  font-size: 80px;\n  font-weight: 400;\n  color: #1F7DB6; }\n  @media (min-width: 768px) {\n    .ErrorPage_errorCode__2Loqq {\n      font-size: 180px; } }\n\n.ErrorPage_errorInfo__3zxdR {\n  font-size: 140px; }\n\n.ErrorPage_errorHelp___ymcC {\n  font-size: 44px;\n  width: 390px; }\n\n.ErrorPage_pageFooter__3-NKh {\n  position: absolute;\n  bottom: 30px;\n  left: 0;\n  right: 0;\n  width: 100%;\n  font-size: 0.9rem;\n  color: #798892;\n  text-align: center; }\n", ""]);

// Exports
exports.locals = {
	"errorPage": "ErrorPage_errorPage__3xWNf",
	"errorContainer": "ErrorPage_errorContainer__1n7L0",
	"errorBtn": "ErrorPage_errorBtn__2GhdA",
	"errorCode": "ErrorPage_errorCode__2Loqq",
	"errorInfo": "ErrorPage_errorInfo__3zxdR",
	"errorHelp": "ErrorPage_errorHelp___ymcC",
	"pageFooter": "ErrorPage_pageFooter__3-NKh"
};