exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "/**\n * Customized version of bootstrap using variables from _variables.scss.\n * This file is loaded via separate loader thus allowing to use original bootstrap classes (e.g. .btn-default) through out the app.\n */\n/*\n * Typography\n * ======================================================================== */\n/**\n * Custom application mixins available through out the app\n */\n/* Chat Scrollbar */\n.Notifications_notifications__U3V7W {\n  height: 100%;\n  border: none; }\n  @media (min-width: 768px) {\n    .Notifications_notifications__U3V7W {\n      width: 333px; } }\n  @media (min-width: 768px) {\n    .Notifications_notificationsAccount__33JQ0 {\n      width: 178px; } }\n\n.Notifications_cardHeader__3l_fx {\n  border-radius: 0; }\n\n.Notifications_cardFooter__KZxvW {\n  padding-top: 14px;\n  padding-bottom: 14px; }\n\n.Notifications_btnNotificationsReload__Op6Wk {\n  color: #323232;\n  outline: none; }\n  .Notifications_btnNotificationsReload__Op6Wk i::before {\n    top: 2px; }\n", ""]);

// Exports
exports.locals = {
	"notifications": "Notifications_notifications__U3V7W",
	"notificationsAccount": "Notifications_notificationsAccount__33JQ0",
	"cardHeader": "Notifications_cardHeader__3l_fx",
	"cardFooter": "Notifications_cardFooter__KZxvW",
	"btnNotificationsReload": "Notifications_btnNotificationsReload__Op6Wk"
};