import axios from "axios";


const baseURL= process.env.APP_TERMINAL_API_URL+ "/api/v1";

class FileUploadDataService {

    getAll(moduleId, countryCode) {
      return axios.get(baseURL+`/files?moduleId=${moduleId}&countryCode=${countryCode}`, {
       headers: {
        "Content-type": "application/json",
         'Authorization': localStorage.getItem('id_token')
       }
      });
    }
  
    get(id) {
      return axios.get(baseURL+`/files/${id}`, {
       headers: {
        "Content-type": "application/json",
         'Authorization': localStorage.getItem('id_token')
       }
      });
    }
  
    create(data) {

      return axios.post(baseURL+`/files`, data, {
       headers: {
         'Authorization': localStorage.getItem('id_token')
       }
      });
    }

}

export default new FileUploadDataService();