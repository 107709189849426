import React from "react";
import PropTypes from "prop-types";
import { withRouter, Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import {Alert, Button, Label, Input, FormGroup} from 'react-bootstrap';
import Widget from "../../components/Widget";
import { loginUser } from "../../actions/user";
import s from './Login.module.scss';
import signinImg from "../../images/signinImg.svg";
import logo from "../../images/login_image.jpeg";
import img1 from "../../images/Vector-1.svg";
import img2 from "../../images/Vector-2.svg";
import img3 from "../../images/Vector-3.svg";
import img4 from "../../images/Vector-4.svg";
import UserService from "../../services/UserService";
import auth from '../../Auth';
import { FaTimesCircle } from "react-icons/fa";
import {setLanguage} from 'redux-i18n';
import ReactFlagsSelect from 'react-flags-select';
import cookies from 'js-cookie'

import { getAnalytics, logEvent } from "firebase/analytics";

class Login extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  static isAuthenticated(token) {
    if (token) return true;
  }

  constructor(props) {
    super(props);

    var countries= ["ES", "GB"];
    var customLabels= {"ES": "ES","GB": "EN"};

    this.state = {
      email: "",
      password: "",
      countries: countries,
      customLabels: customLabels
    };

    this.doLogin = this.doLogin.bind(this);
    this.resendPassword = this.resendPassword.bind(this);
    this.googleLogin = this.googleLogin.bind(this);
    this.microsoftLogin = this.microsoftLogin.bind(this);
    this.changeEmail = this.changeEmail.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.signUp = this.signUp.bind(this);
  }

  resendPassword (event, link){

    event.preventDefault();

    this.setState({
        loading: true,
        errorLogin: null,
        errorNetLogin: null,
        success: false
    })

    UserService.postByUrl(link,this.props.lang).then(response => {
        const analytics = getAnalytics();
        logEvent(analytics, 'login', response);
        this.setState({
            loading: false,
            success: true
        })
    })
    .catch(e => {
        this.setState ({
            loading: false,
            errorLogin: e.response,
            errorNetLogin: e.message
        })
    });
  }


  setCountryCode(code) {
    cookies.set('i18lang', code);
    this.props.dispatch(setLanguage(code))
  }

  changeEmail(event) {
    this.setState({ email: event.target.value });
    event.target.setCustomValidity('');
  }

  changePassword(event) {
    this.setState({ password: event.target.value });
    event.target.setCustomValidity('');
  }

  doLogin(e) {
    e.preventDefault();

    this.setState ({
        loading: true
    });

    UserService.login(this.state.email, this.state.password).then(response => {
        localStorage.setItem('id_token', response.data.token)
            this.props.dispatch(
            loginUser({ email: this.state.email, password: this.state.password })
        );
        window.location.reload();
    })
    .catch(e => {
        this.setState ({
            loading: false,
            errorLogin: e.response,
            errorKey: (e.response != null && e.response.data != null && e.response.data.error_key != null) ? e.response.data.error_key : null,
            errorLink: (e.response != null && e.response.data != null && e.response.data.link != null) ? e.response.data.link : null,
            errorNetLogin: e.message
        })
    });
  }

  googleLogin() {
    this.props.dispatch(loginUser({ social: "google" }));
  }

  microsoftLogin() {
    this.props.dispatch(loginUser({ social: "microsoft" }));
  }

  signUp() {
    this.props.history.push("/register");
  }

  render() {
    const { from } = this.props.location.state || { from: { pathname: '/app' } }; // eslint-disable-line

    let loading;

    if (this.state.loading) {
      loading = (
        <div>
          {this.context.t('loading')}
        </div>
      )
    }

    var isAuthenticated = auth.isAuthenticated();

    // cant access login page while logged in
    if (isAuthenticated) {
      return (
          <Redirect to={from} />
      );
    }

    let successMsj;

    if (this.state.success){
        successMsj = <Alert variant="success" style={{margin: "20px"}}>
            {this.context.t('mail.check')}
         </Alert>;
    }

    let errorLogin

    if (this.state.errorNetLogin) {
      errorLogin = <Alert variant="danger" style={{margin: "20px"}}>
           {this.context.t('login.error')}
        </Alert>;
    }

    if (this.state.errorLogin) {
      var errorKey = this.state.errorKey;
      var errorLink = this.state.errorLink;

      errorLogin = <Alert variant="danger" style={{margin: "20px"}}>
                <strong><FaTimesCircle/></strong> {this.context.t(errorKey)}
                {errorKey == 'user_inactive' ? (
                     <a onClick={event => this.resendPassword(event, errorLink)}> {this.context.t('click.here')} </a>
                ) : (
                    ""
                )}
        </Alert>;
    }

    return (

        <section>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        {errorLogin}
                        {successMsj}
                        {loading}
                        <div className="wrapper-page">

                            <div className="m-t-40 card-box">
                                <div className="text-center">
                                    <h2 className="text-uppercase m-t-0 m-b-30">
                                        <a className="text-success"> <span><img src={logo} alt="" height="70&quot;"/></span>
                                        </a>
                                    </h2>
                                </div>
                                <div className="account-content">

                                    <ReactFlagsSelect
                                        selected={this.props.lang}
                                        countries={this.state.countries}
                                        onSelect={code => this.setCountryCode(code)}
                                        customLabels={this.state.customLabels}
                                        placeholder={this.context.t('lang.select')} required/>

                                    <form method="POST" className="form-horizontal" onSubmit={this.doLogin}>
                                        <div className="form-group m-b-20">
                                            <div className="col-12">
                                                <label htmlFor="emailaddress"> {this.context.t('login.user')}</label>
                                                <input className="form-control" tabIndex="1" placeholder={this.context.t('login.user')} onChange={this.changeEmail} type="text" required onInvalid={e => e.target.setCustomValidity(this.context.t('field.required'))} onValid={e => e.target.setCustomValidity('')}/>
                                            </div>
                                        </div>

                                        <div className="form-group m-b-20">
                                            <div className="col-12">
                                            <label htmlFor="password">{this.context.t('login.password')}</label>
                                            <input className="form-control" tabIndex="2" placeholder={this.context.t('login.password')} onChange={this.changePassword} type="password" required onInvalid={e => e.target.setCustomValidity(this.context.t('field.required'))} onValid={e => e.target.setCustomValidity('')}/>
                                            </div>
                                        </div>

                                        <div className="form-group account-btn text-center m-t-10">
                                            <div className="col-12">
                                                <button id="btnLogin" className="btn btn-lg btn-block button button--primary" tabIndex="2" type="submit">{this.context.t('login.login')}</button>
                                                <br/><br/>
                                            </div>
                                        </div>
                                        <a href="/induccion/resetPassword" tabIndex="5" className="text-muted pull-right font-14">{this.context.t('login.forgot_password')}</a><br/>
                                        <a href="/induccion/contactUs" tabIndex="5" className="text-muted pull-right font-14">{this.context.t('login.contact_us')}</a><br/>

                                    </form>

                                    <div className="clearfix"></div>

                                </div>
                            </div>
                            <div className="row m-t-50">
                                <div className="col-sm-12 text-center">
                                    <p className="text-muted">
                                        {this.context.t('login.have_account')} <a href="/induccion/userRegister" className="text-dark m-l-5"><strong>{this.context.t('login.check_in')}</strong></a>
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
  }
}

Login.contextTypes = {
  t: PropTypes.func.isRequired
}

function mapStateToProps(state) {
  return {
    isFetching: state.auth.isFetching,
    isAuthenticated: state.auth.isAuthenticated,
    errorMessage: state.auth.errorMessage,
    lang: state.i18nState.lang
  };
}

export default withRouter(connect(mapStateToProps)(Login));
